<template>
  <v-container fluid>
    <!-- editor -->
    <editor
      :currencies="currencies"
      :initial="dataItem"
      :pageMode="pageMode"
      @data="save"
    ></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    pageMode: "create",
    currencies: [],
  }),
  methods: {
    save(data) {
      const url = "/item_masterdata";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "item res");
          // self.$refs.snackbar.show("Item group created", "green");
          if (res.ResultCode == 1200) {
            self.$router.push("/inventory/item-master-data");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>